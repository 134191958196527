export const availableColors = [
    "#202020",
    "#b9b9b9",
    "#3b3b3b",
    "#400000",
    "#cf0000",
    "#db7c01",
    "#c28920",
    "#e8e21c",
    "#000537",
    "#0325d2",
    "#2a4f8f",
    "#2e78b5",
    "#8bb3cc",
    "#aae4dc",
    "#50aca9",
    "#065a4b",
    "#072501",
    "#027e04",
    "#657f19",
    "#97cb5f",
    "#4c1e66",
    "#c584e2",
    "#630d3a",
    "#c89cad",
    "#e7c8da",
    "#402d1e",
    "#e3c69e"
]