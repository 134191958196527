import { availableColors } from "../components/Customize3d/utils/colors";

export const initialState = {
    elements: {
        front: [],
        back: [],
        left: [],
        right: []
    },
    colors: {
        one: availableColors[1],
        two: availableColors[0],
    },
};

export const elementReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_ELEMENT':
            return {
                ...state,
                elements: {
                    ...state.elements,
                    [action.side]: [...state.elements[action.side], action.payload]
                }
            };
        case 'UPDATE_ELEMENT':
            console.log('Element is being updated')
            return {
                ...state,
                elements: {
                    ...state.elements,
                    [action.side]: state.elements[action.side].map((el) =>
                        el.id === action.payload.id ? { ...el, ...action.payload.props } : el
                    )
                }
            };
        case 'REMOVE_ELEMENT':
            return {
                ...state,
                elements: {
                    ...state.elements,
                    [action.side]: state.elements[action.side].filter((el) => el.id !== action.payload.id)
                }
            };
        case 'REORDER_ELEMENTS':
            const { sourceIndex, destinationIndex, side } = action.payload;

            // Create a copy of the elements array for the specified side
            const newElements = Array.from(state.elements[side]);

            // Remove the dragged element
            const [removed] = newElements.splice(sourceIndex, 1);

            // Insert the removed element at the destination index
            newElements.splice(destinationIndex, 0, removed);

            // Return the reordered elements with updated ordering
            return {
                ...state,
                elements: {
                    ...state.elements,
                    [side]: newElements.map((el, idx) => ({
                        ...el,
                        ordering: idx + 1,
                    }))
                }
            };

        case 'UPDATE_COLOR_ONE':
            return {
                ...state,
                colors: {
                    one: action.payload.newColor,
                    two: state.colors.two
                }
            };

        case 'UPDATE_COLOR_TWO':
            return {
                ...state,
                colors: {
                    one: state.colors.one,
                    two: action.payload.newColor
                }
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
