// TShirtColorController.jsx
import { useEffect } from "react";
import * as THREE from "three";
import { useElementContext } from "../../context/ElementContext";

const TShirtColorController = ({ scene, model }) => {
    const {colors} = useElementContext()
    useEffect(() => {
        scene.traverse((child) => {
            if (child.isMesh) {
                if (model.color_two_meshes.includes(child.name)) {
                    child.material = new THREE.MeshStandardMaterial({
                        color: colors.two,
                    });
                }
                if (model.color_one_meshes.includes(child.name)) {
                    child.material = new THREE.MeshStandardMaterial({
                        color: colors.one,
                    });
                }
            }
            child.castShadow = true;
        });
    }, [colors, scene]);

    return null;
};

export default TShirtColorController;
