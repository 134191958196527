import React from "react";

const TextForm = ({ text, setText, textColor, setTextColor, handleAddText, loading }) => (
    <div>
        <form className="flex flex-col gap-1">
            <input
                type="text"
                value={text}
                className="w-full rounded-md px-2 py-1 outline-none"
                onChange={(e) => setText(e.target.value)}
                placeholder="Add Text"
                disabled={loading}
            />
            <button
                className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-4 py-2 text-center me-2 mb-2 w-1/2"
                onClick={handleAddText}
                disabled={loading}
            >
                Add Text
            </button>
        </form>
    </div>
);

export default TextForm;
