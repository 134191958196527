import {useControls} from "leva";
import * as THREE from "three";
import {useElementContext} from "../../context/ElementContext";
import {useEffect, useState} from "react";
const TextControls = ({index, side}) => {
    const {elements, updateElement} = useElementContext(side);
    let element = elements[index];

    // use a key to force re-initialization of useControls when element changes
    const key = `${side}-Element-${index + 1}`;

    const schema = {
        content: {
            value: element.content || null,
            onChange: (e) => {
                updateElement(element.id, {
                    content: e,
                });
            },
        },
        color: {
            value: element.color || null,
            onChange: (e) => {
                updateElement(element.id, {
                    color: e,
                });
            },
        },
        position: {
            value: [...element.position] || null,
            min: -0.3,
            max: 0.3,
            step: 0.001,
            onChange: (e) => {
                updateElement(element.id, {
                    position: new THREE.Vector3(...e),
                });
            },
        },
        scale: {
            value: [...element.scale] || null,
            min: 0.4,
            max: 1.5,
            step: 0.01,
            onChange: (e) => {
                updateElement(element.id, {
                    scale: new THREE.Vector3(...e),
                });
            },
        },
        edit: {
            value: element.edit || true,
            onChange: (e) => {
                updateElement(element.id, {
                    edit: e,
                });
            },
        },
    }

    const [_, set] = useControls(key ,() => schema);

    useEffect(() => {
        set({
            content: element.content,
            color: element.color,
            position: [...element.position],
            scale: [...element.scale],
            edit: element.edit,
        });
    }, [element]);

    return null;
};

export default TextControls;
