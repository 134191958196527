import React, {useEffect, useState} from "react";
import {Canvas} from "@react-three/fiber";
import {
    AccumulativeShadows,
    OrbitControls,
    RandomizedLight,
} from "@react-three/drei";
import Lights from "./Lights";
import Model from "./Model";
import Controls from "./controls/Controls";
import Ground from "./Ground";
import {models} from "./utils/models";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {ElementProvider, useElementContext} from "../../context/ElementContext";

const Customize3d = () => {
    const [side, setSide] = useState("front");
    return (
        <div className="border border-gray-200 p-8 w-full rounded-md flex flex-col gap-4 bg-white shadow-md">
            {/* <h1 className="text-2xl font-semibold">Customize your Uniform</h1> */}
            <Tabs className="pt-0 mt-0">
                <TabList>
                    <div className="flex gap-1 justify-center items-center">
                        {models.map((model, index) => (
                            <Tab key={index}>
                                <div className="flex flex-col items-center justify-center">
                                    <img
                                        src={model.picture_path}
                                        width={50}
                                        className="rounded-md border border-white"
                                    />
                                    <h2 key={index}>{model.name}</h2>
                                </div>
                            </Tab>
                        ))}
                    </div>
                </TabList>

                {models.map((model, index) => (
                    <TabPanel key={index}>
                        <div className="flex flex-col h-[100vh] lg:flex-row w-full gap-10">
                            <ElementProvider>
                                <Canvas
                                    shadows
                                    className="border shadow-md w-full rounded-md flex-1"
                                    style={{height: "500px"}}>
                                    <Lights />
                                    <Model side={side} model={model} />
                                    <OrbitControls
                                        // enablePan={false}
                                        minPolarAngle={((Math.PI / 2) - 0.2)}
                                        maxPolarAngle={Math.PI / 2}
                                        makeDefault
                                    />
                                    <Lights />
                                    <Ground />
                                </Canvas>
                                <Controls
                                    index={index}
                                    side={side}
                                    setSide={setSide}
                                />
                            </ElementProvider>
                        </div>
                    </TabPanel>
                ))}
            </Tabs>
        </div>
    );
};

export default Customize3d;
