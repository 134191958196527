import React from "react";

const SidewayChoice = ({ children, side, setSide }) => {

    return (
        <>
            <div className="flex flex-col gap-5">
                <div>
                    <div className="sm:hidden">
                        <select
                            id="tabs"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            value={side}
                            onChange={(e) => setSide(e.target.value)}
                        >
                            <option value="front">
                                Front
                            </option>
                            <option value="back">
                                Back
                            </option>
                            <option value="right">
                                Right
                            </option>
                            <option value="left">
                                Left
                            </option>
                        </select>
                    </div>
                    <ul className="hidden text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
                        <li
                            className="w-full focus-within:z-10"
                            onClick={() => setSide("front")}
                        >
                            <a
                                href="#"
                                className={`inline-block w-full p-4 border-r border-gray-200 dark:border-gray-700 rounded-s-lg dark:text-white ${
                                    side === "front"
                                        ? " bg-gray-100 dark:bg-gray-700 focus:ring-4 focus:ring-blue-300 active focus:outline-none"
                                        : "text-gray-900 bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:bg-gray-800 dark:hover:bg-gray-700"
                                }`}
                                aria-current={
                                    side === "front" ? "page" : undefined
                                }
                            >
                                Front
                            </a>
                        </li>
                        <li
                            className="w-full focus-within:z-10"
                            onClick={() => setSide("back")}
                        >
                            <a
                                href="#"
                                className={`inline-block w-full p-4 border-r border-gray-200 dark:border-gray-700 dark:text-white ${
                                    side === "back"
                                        ? "text-white bg-gray-100 dark:bg-gray-700 focus:ring-4 focus:ring-blue-300 active focus:outline-none"
                                        : "text-gray-900 bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:bg-gray-800 dark:hover:bg-gray-700"
                                }`}
                            >
                                Back
                            </a>
                        </li>
                        <li
                            className="w-full focus-within:z-10"
                            onClick={() => setSide("right")}
                        >
                            <a
                                href="#"
                                className={`inline-block w-full p-4 border-r border-gray-200 dark:border-gray-700 dark:text-white ${
                                    side === "right"
                                        ? "text-white bg-gray-100 dark:bg-gray-700 focus:ring-4 focus:ring-blue-300 active focus:outline-none"
                                        : "text-gray-900 bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:bg-gray-800 dark:hover:bg-gray-700"
                                }`}
                            >
                                Right
                            </a>
                        </li>
                        <li
                            className="w-full focus-within:z-10"
                            onClick={() => setSide("left")}
                        >
                            <a
                                href="#"
                                className={`inline-block w-full p-4 border-s-0 border-gray-200 dark:border-gray-700 rounded-e-lg dark:text-white ${
                                    side === "left"
                                        ? "text-white bg-gray-100 dark:bg-gray-700 focus:ring-4 focus:ring-blue-300 active focus:outline-none"
                                        : "text-gray-900 bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:bg-gray-800 dark:hover:bg-gray-700"
                                }`}
                            >
                                Left
                            </a>
                        </li>
                    </ul>
                </div>
                <>{children}</>
            </div>
        </>
    );
};

export default SidewayChoice;
