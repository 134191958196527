//IMPORTING IMAGES
function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
        images[item.replace("./", "")] = r(item);
    });
    return images;
}
const model = importAll(require.context("./model", true, /\.(glb|jpe?g|png)$/));

export const models = [
    {
        name: "Main Design",
        path: model["model-1/polo-tshirt-design.glb"],
        picture_path: model["model-1/design.jpeg"],
        front_meshes: [
            "ShirtpolySurface36_re_work_phong1__0"
        ],
        back_meshes: [
            "polySurface4_re_work_phong1__0"
        ],
        right_meshes: [
            "ShirtpolySurface38_re_work_phong1__0"
        ],
        left_meshes: [
            "ShirtpolySurface39_re_work_phong1__0"
        ],
        color_one_meshes: [
            "ShirtpolySurface36_re_work_phong1__0",
            "ShirtpolySurface38_re_work_phong1__0",
            "ShirtpolySurface39_re_work_phong1__0",
            "polySurface4_re_work_phong1__0",
            "polySurface37_re_work_phong1__0",
            "polySurface3_re_work_phong1__0",
            "polySurface38_re_work_phong1__0",
            "polySurface3_re_work_phong1__0",
            "polySurface4_re_work_phong1__0002",
            "polySurface4_re_work_phong1__0003",
            "polySurface37_re_work_phong1__0",
            "polySurface38_re_work_phong1__0",
            "ShirtpolySurface36_re_work_phong1__0002",
            "ShirtpolySurface36_re_work_phong1__0003"
        ],
        color_two_meshes: [
        ],
    },
    {
        name: "Model 2",
        path:model["model-2/polo-tshirt-design.glb"],
        picture_path: model["model-2/design.jpeg"],
        front_meshes: [
            "ShirtpolySurface36_re_work_phong1__0",
            "ShirtpolySurface36_re_work_phong1__0002",
            "ShirtpolySurface36_re_work_phong1__0003"
        ],
        back_meshes: [
            "polySurface4_re_work_phong1__0",
            "polySurface4_re_work_phong1__0002",
            "polySurface4_re_work_phong1__0003"
        ],
        right_meshes: [
            "ShirtpolySurface38_re_work_phong1__0",
            "polySurface3_re_work_phong1__0",
        ],
        left_meshes: [
            "ShirtpolySurface39_re_work_phong1__0",
            "polySurface38_re_work_phong1__0",
        ],
        color_one_meshes: [
            
            "polySurface3_re_work_phong1__0",
            "polySurface4_re_work_phong1__0002",
            "polySurface4_re_work_phong1__0003",
            "polySurface37_re_work_phong1__0",
            "polySurface38_re_work_phong1__0",
            "ShirtpolySurface36_re_work_phong1__0002",
            "ShirtpolySurface36_re_work_phong1__0003"
        ],
        color_two_meshes: [
            "polySurface4_re_work_phong1__0",
            "polySurface37_re_work_phong1__0001",
            "ShirtpolySurface36_re_work_phong1__0",
            "ShirtpolySurface38_re_work_phong1__0",
            "ShirtpolySurface39_re_work_phong1__0",
        ],
    }
]