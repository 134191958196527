import React from "react";

const ImageUpload = ({ handleAddImage, loading }) => (
    <div>
        <label className="text-gray-700" htmlFor="image-input">
            Upload file
        </label>
        <input
            disabled={loading}
            type="file"
            id="image-input"
            className="cursor-pointer flex h-10 w-full rounded-md border border-input bg-white px-3 py-2 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
            onChange={handleAddImage}
        />
    </div>
);

export default ImageUpload;
