import {useEffect, useRef} from "react";
import * as THREE from "three";
import {DecalGeometry} from "three/examples/jsm/geometries/DecalGeometry";
import DecalElement from "./DecalElement";
import createTextTexture from "./createTexture/createTextTexture";
import {useElementContext} from "../../context/ElementContext";

const DecalMeshGroup = ({scene, meshNames, side, yOffset = 0}) => {
    const {elements, updateElement, removeElement} = useElementContext(side);
    const decalMeshRef = useRef(new THREE.Group());
    const meshRef = useRef();

    useEffect(() => {
        const updateElements = () => {
            scene.traverse((child) => {
                if (child.isMesh) console.log(child);
                if (child.isMesh && meshNames.includes(child.name)) {
                    meshRef.current = child;
                    elements.forEach((element) => {
                        const texture =
                            element.type === "image"
                                ? element.texture
                                : createTextTexture(
                                      element.content,
                                      element.color,
                                      element.size
                                  );

                        if (texture) {
                            texture.repeat.set(1, 1);
                            const decalMaterial = new THREE.MeshBasicMaterial({
                                map: texture,
                                transparent: true,
                                depthTest: true,
                                depthWrite: true,
                                polygonOffset: true,
                                polygonOffsetFactor: -1,
                            });

                            const decalPosition = new THREE.Vector3(
                                ...element.position
                            );
                            const decalScale = new THREE.Vector3(
                                ...element.scale
                            );
                            const decalRotation = new THREE.Euler(
                                ...element.rotation
                            );

                            const decalGeometry = new DecalGeometry(
                                child,
                                decalPosition,
                                decalRotation,
                                decalScale
                            );

                            const decalMesh = new THREE.Mesh(
                                decalGeometry,
                                decalMaterial
                            );

                            decalMesh.renderOrder = element.ordering * -1;
                            decalMeshRef.current.add(decalMesh);
                            decalMesh.material.needsUpdate = true;
                        }
                    });
                }
            });
        };

        if (decalMeshRef.current) {
            while (decalMeshRef.current.children.length) {
                decalMeshRef.current.remove(decalMeshRef.current.children[0]);
            }
        }

        updateElements();
        console.log(elements);
    }, [elements]);

    return (
        <>
            <group ref={decalMeshRef} />
            {elements.map((element, index) => (
                <DecalElement
                    key={element.id}
                    mesh={meshRef.current}
                    decalMeshRef={decalMeshRef}
                    element={element}
                    updateElement={updateElement}
                    removeElement={removeElement}
                    index={index}
                    yOffset={yOffset}
                    side={side}
                />
            ))}
        </>
    );
};

export default DecalMeshGroup;
