import React, {useEffect, useRef, useState} from "react";
import * as THREE from "three";
import {PivotControls, Decal} from "@react-three/drei";
import createTextTexture from "./createTexture/createTextTexture";
import TextControls from "./TextControls";
import ImageControls from "./ImageControls";

const DecalElement = ({element, updateElement, index, mesh, side}) => {
    // Define initial rotation based on side
    const initialRotation =
        side === "front"
            ? new THREE.Euler(0, 0, 0)
            : side === "back"
            ? new THREE.Euler(0, Math.PI, 0)
            : side === "right"
            ? new THREE.Euler(0, Math.PI / 2, 0)
            : side === "left"
            ? new THREE.Euler(0, -Math.PI / 2, 0)
            : new THREE.Euler(0, 0, 0); // default to front rotation

    const [controlValues, setControlValues] = useState({
        content: element.content,
        color: element.color,
        position: element.position,
        scale: element.scale,
        edit: element.edit,
    });

    // Sync the controls with the element state
    useEffect(() => {
        setControlValues({
            content: element.content,
            color: element.color,
            position: element.position,
            scale: element.scale,
            edit: element.edit,
        });
    }, [element]);

    const handleDrag = (local) => {
        const newPos = controlValues.position.clone();
        const newScale = controlValues.scale.clone();
        const newQuat = new THREE.Quaternion(...element.rotation, "XYZ");
        const newRot = new THREE.Euler(...element.rotation);

        // Decompose the transformation matrix into position, quaternion, and scale
        local.decompose(newPos, newQuat, newScale);

        // Apply quaternion to Euler
        newRot.setFromQuaternion(newQuat);

        // Adjust rotation based on the side
        if (side === "back") {
            newRot.y += Math.PI; // Rotate by 180 degrees for the back
        } else if (side === "right") {
            newPos.y += 0.2;
            newRot.y += Math.PI / 2; // Rotate by 90 degrees for the right
        } else if (side === "left") {
            newPos.y += 0.2;
            newRot.y -= Math.PI / 2; // Rotate by -90 degrees for the left
        }

        // Update the element's position, rotation, and scale
        updateElement(element.id, {
            position: newPos.clone(),
            rotation: newRot.clone(),
            scale: newScale.clone(),
        });
    };
    return (
        <>
            {element.type === "text" && (
                <TextControls
                    type={element.type}
                    passedElement={element}
                    index={index}
                    updateElement={updateElement}
                    side={side}
                />
            )}
            {element.type === "image" && (
                <ImageControls
                    type={element.type}
                    passedElement={element}
                    index={index}
                    updateElement={updateElement}
                    side={side}
                />
            )}
            <PivotControls
                offset={
                    side === "front"
                        ? [0, 0, 0.18]
                        : side === "back"
                        ? [0, 0, -0.18]
                        : side === "right"
                        ? [0.3, 0.2, 0]
                        : side === "left"
                        ? [-0.3, 0.2, 0]
                        : [0, 0, 0] // default offset
                }
                rotation={initialRotation} // Use initial rotation if no rotation set
                key={index}
                activeAxes={[true, true, false]}
                enabled={element.edit}
                depthTest={true}
                scale={0.15}
                onDrag={handleDrag}>
                <mesh ref={mesh} geometry={mesh.geometry}>
                    {/* <Decal
                        // position={controlValues.position}
                        // rotation={controlValues.rotation}
                        // scale={new THREE.Vector3(controlValues.scale.x, controlValues.scale.y, controlValues.scale.z ** 0)}
                        // // map={
                        // //     element.type === "image"
                        // //         ? element.texture
                        // //         : createTextTexture(
                        // //               element.content,
                        // //               element.color,
                        // //               element.size
                        // //           )
                        // // }
                        material-depthTest={true}
                    /> */}
                </mesh>
            </PivotControls>
        </>
    );
};

export default DecalElement;
