import React, {useEffect, useRef, useState} from "react";
import * as THREE from "three";
import {css, styled} from "styled-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";

import TextForm from "./TextForm";
import ImageUpload from "./ImageUpload";
import ElementControls from "./ElementControls";
import {useElementContext} from "../../../../context/ElementContext";

const ArrowDownIcon = styled(KeyboardArrowDownIcon)`
    ${(props) =>
        props.active &&
        css`
            transform: rotate(180deg);
        `}
    transition: transform 300ms ease-in-out !important;
`;

const RightControls = () => {
    const side = 'right'
    const [text, setText] = useState("");
    const [textColor, setTextColor] = useState("#000");
    const [activeSectionId, setActiveSectionId] = useState(null);

    const [maxId, setMaxId] = useState(0);
    const idCounter = useRef(maxId + 1);

    const {
        elements,
        addElement,
        updateElement,
        removeElement,
        reorderElements
    } = useElementContext(side);

    // Track max ID in useEffect
    useEffect(() => {
        if (elements.length > 0) {
            setMaxId(Math.max(...elements.map((e) => e.id)));
            idCounter.current = Math.max(...elements.map((e) => e.id)) + 1;
        }
        sortElementsByOrdering(elements);
    }, [elements]);

    const generateNextId = () => {
        return idCounter.current++;
    };
    // Sort elements by ordering
    const sortElementsByOrdering = (elements) => {
        return elements.sort((a, b) => a.ordering - b.ordering);
    };

    const handleAddText = (e) => {
        e.preventDefault();
        if (text.trim() === "") return;

        const newOrdering =
            (elements.length > 0
                ? Math.max(...elements.map((e) => e.ordering))
                : 0) + 1;

        const newElement = {
            id: generateNextId(),
            ordering: newOrdering,
            type: "text",
            color: textColor,
            content: text.trim(),
            edit: true,
            position: new THREE.Vector3(0, 0.2, 0),
            rotation: new THREE.Euler(0, Math.PI / 2, 0),
            scale: new THREE.Vector3(1, 1, 1),
            size: 18,
        };
        console.log('element added')
        addElement(newElement);
        setText("");
    };

    const handleAddImage = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        const newOrdering =
            (elements.length > 0
                ? Math.max(...elements.map((e) => e.ordering))
                : 0) + 1;

        reader.onload = (e) => {
            const textureLoader = new THREE.TextureLoader();
            textureLoader.load(
                e.target.result,
                (texture) => {
                    if (texture) {
                        texture.repeat.set(1, 1); // Set texture to show only once
                        const newElement = {
                            id: generateNextId(),
                            ordering: newOrdering,
                            type: "image",
                            texture: texture,
                            edit: true,
                            position: new THREE.Vector3(0, 0.2, 0),
                            rotation: new THREE.Euler(0, Math.PI / 2, 0),
                            scale: new THREE.Vector3(0.5, 0.5, 0.5),
                        };
                        addElement(newElement);
                    } else {
                        console.error("Failed to load texture.");
                    }
                },
                undefined,
                (error) => {
                    console.error("Error loading texture:", error);
                }
            );
        };
        reader.readAsDataURL(file);
        document.getElementById("image-input").value = "";
    };

    const deleteElement = (element) => {
        removeElement(element.id);
        return sortElementsByOrdering(elements).map((element, index) => ({
            ...element,
            ordering: index + 1,
        }));
    };

    const handleDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination) {
          console.log("No destination for drag result");
          return;
        }
    
        console.log("Drag result:", result);
        console.log("Source index:", source.index);
        console.log("Destination index:", destination.index);
    
        // Dispatch the reorderElements action through context
        reorderElements(source.index, destination.index);
      };
    return (
        <div className="flex flex-col gap-4 flex-1">
            <ImageUpload handleAddImage={handleAddImage} />
            <TextForm
                text={text}
                setText={setText}
                handleAddText={handleAddText}
            />
            <div>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="flex flex-col w-full gap-2 overflow-y-scroll h-48">
                                {elements.map((element, index) => (
                                    <Draggable
                                        key={index}
                                        draggableId={`${index}`}
                                        index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}>
                                                <div className="flex flex-row justify-center items-center gap-3">
                                                    <span
                                                        {...provided.dragHandleProps}>
                                                        <DragIndicatorIcon className="cursor-grab" />
                                                    </span>
                                                    <button
                                                        className="bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded w-full flex flex-row justify-between"
                                                        onClick={() =>
                                                            setActiveSectionId(
                                                                (prev) =>
                                                                    prev ===
                                                                    index
                                                                        ? null
                                                                        : index
                                                            )
                                                        }>
                                                        <span>
                                                            {element.type ===
                                                            "text"
                                                                ? element.content
                                                                : `Image ${
                                                                      index + 1
                                                                  }`}
                                                        </span>
                                                        <div>
                                                            <ArrowDownIcon
                                                                active={
                                                                    activeSectionId ===
                                                                    index
                                                                        ? "true"
                                                                        : "false"
                                                                }
                                                            />
                                                        </div>
                                                    </button>
                                                    <span className="rounded-sm bg-red-700 p-1 hover:bg-red-900">
                                                        <DeleteIcon
                                                            className="text-white cursor-pointer"
                                                            onClick={() =>
                                                                deleteElement(
                                                                    element
                                                                )
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                                {activeSectionId === index && (
                                                    <ElementControls
                                                        passedElement={element}
                                                        index={index}
                                                        updateElement={updateElement}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
};

export default RightControls;
