import {useControls} from "leva";
import * as THREE from "three";
import { useElementContext } from "../../context/ElementContext";
import { useEffect } from "react";
const ImageControls = ({index, side}) => {
    // Check if the image is an HTMLImageElement and get the src attribute
    const { elements, updateElement } = useElementContext(side)
    const element = elements[index]
    let imagePath = "";
    const image = element.texture.image;
    if (image instanceof HTMLImageElement) {
        imagePath = image.src;
    } else if (image && image.src) {
        // This handles other types of images like canvas or video
        imagePath = URL.createObjectURL(image.src);
    }



    const key = `${side}-Element-${index + 1}`
    const schema = {
        image: {
            image: {
                value: imagePath || null, // Default to current texture image path
                onChange: (e) => {
                    // Load new texture when image path changes
                    new THREE.TextureLoader().load(e, (newTexture) => {
                        updateElement(element.id, {
                            texture: newTexture,
                        });
                    });
                },
            },
        },
        position: {
            value: [...element.position] || null,
            min: -0.3,
            max: 0.3,
            step: 0.001,
            onChange: (e) => {
                updateElement(element.id, {
                    position: new THREE.Vector3(...e),
                });
            },
        },
        scale: {
            value: [...element.scale] || null,
            min: 0.01,
            max: 2,
            step: 0.01,
            onChange: (e) => {
                updateElement(element.id, {
                    scale: new THREE.Vector3(...e, 1),
                });
            },
        },
        edit: {
            value: element.edit || true,
            onChange: (e) => {
                updateElement(element.id, {
                    edit: e,
                });
            },
        }
    }
    const [_, set] = useControls(key,() => schema);

    useEffect(() => {
        set({
            image: {
                image: imagePath
            },
            position: [...element.position],
            scale: [...element.scale],
            edit: element.edit,
        });
    }, [element]);
    return null;
};

export default ImageControls;
